/*
 *  _     _           _       _   _
 * (_)   | |         | |     | | (_)
 *  _  __| |___  ___ | |_   _| |_ _  ___  _ __  ___
 * | |/ _` / __|/ _ \| | | | | __| |/ _ \| '_ \/ __|
 * | | (_| \__ \ (_) | | |_| | |_| | (_) | | | \__ \
 * |_|\__,_|___/\___/|_|\__,_|\__|_|\___/|_| |_|___/
 *
 * PHP version 7.0
 *
 * @category  ochsner
 * @package   Themes
 *
 * @author    maoh <office@idsolutions.at>
 * @copyright 2020 idsolutions gmbh
 *
 * @see https://code.idsolutions.at/projects/TORSUP/repos/themes
 */

class BaseModule {
  constructor() {
    console.debug('call new for', this);
  }

  async init() {
    console.error('You extend from BaseModule but have no custom init() method!');
  }

  /**
   * Create new instance
   *
   * @static
   * @returns {this} New instance
   */
  static create() {
    return new this();
  }
}

export default BaseModule;
