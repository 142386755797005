import $ from 'jquery';
import BaseModule from './BaseModule';

/**
 * Main project
 */
class IdsProduct extends BaseModule {
  async init() {
    $('.tx-ids-product').removeClass('invisible');

    this._initSearchVariantHandling();
    this._initGetRecords();
  }

  /**
   * init function for handling search variant
   */
  async _initSearchVariantHandling() {
    // reset button
    $(document).on('click', 'a.button-reset-filter', (event) => {
      $('input#fulltext').val('');

      // remove class inactive to documents filter
      $('.ids-product-documents .documents-filter').removeClass('inactive');

      // enable select fields in documents filter
      $.each($('.ids-product-documents .documents-filter select'), function () {
        $(this).removeAttr('disabled');
      });

      // clearSelection('fulltext');
      $('.section-document-info').show();

      // hide reset button
      $('.reset-fulltext').fadeOut();

      this._clearSelection('fulltext');

      event.preventDefault();
      return false;
    });

    $(document).on('focus', '.ids-product-documents .section-fulltext input#fulltext', (event) => {
      $('.section-fulltext').removeClass('inactive');
    });

    $(document).on('keyup', '.ids-product-documents .section-fulltext input#fulltext', (event) => {
      // only if enter is not pressed
      if (event.which !== 13) {
        // show reset button
        $('.reset-fulltext').fadeIn();

        // add class inactive to documents filter
        $('.ids-product-documents .documents-filter').addClass('inactive');

        // disable select fields in documents filter
        $.each($('.ids-product-documents .documents-filter select'), function () {
          $(this).prop('disabled', 'disabled');
        });

        // all characters are removed?
        if (event.target.value.length === 0) {
          // remove class inactive to documents filter
          $('.ids-product-documents .documents-filter').removeClass('inactive');

          // enable select fields in documents filter
          $.each($('.ids-product-documents .documents-filter select'), function () {
            $(this).removeAttr('disabled');
          });

          // clearSelection('fulltext');
          $('.section-document-info').show();
        }

        this._clearSelection('fulltext');
      }
    });
  }

  /**
   * init function for ajax request
   */
  async _initGetRecords() {
    // fulltext search
    $(document).on('submit', 'form#product-documents', (event) => {
      this._ajaxGetRecords(event.currentTarget, 'fulltext');

      // hide info
      $('.section-document-info').hide();

      // language detection
      $('.ids-product-documents .section-language.inactive').removeClass('inactive');
      $('.ids-product-documents .section-language select').removeAttr('disabled');

      event.stopImmediatePropagation();
      return false;
    });

    // filter search
    $(document).on('change', '.ids-product-documents .section-type select#type', (event) => {
      this._ajaxGetRecords(event.currentTarget, 'heatsource');
      this._clearSelection('heatsource');

      $('.section-document-info').show();

      $('.section-fulltext').addClass('inactive');
    });

    $(document).on('change', '.ids-product-documents .section-heatsource select#heatsource', (event) => {
      this._ajaxGetRecords(event.currentTarget, 'modelserie');
      this._ajaxGetRecords(event.currentTarget, 'product');
      this._clearSelection('modelserie');
      this._clearSelection('product');

      $('.section-document-info').show();

      event.stopImmediatePropagation();
    });

    $(document).on('change', '.ids-product-documents .section-modelserie select#modelserie', (event) => {
      this._ajaxGetRecords(event.currentTarget, 'product');
      this._clearSelection('product');

      $('.section-document-info').show();

      event.stopImmediatePropagation();
    });

    $(document).on('change', '.ids-product-documents .section-product select#product', (event) => {
      this._ajaxGetRecords(event.currentTarget, 'document');

      $('.section-document-info').hide();

      // language detection
      $('.ids-product-documents .section-language.inactive').removeClass('inactive');
      $('.ids-product-documents .section-language select').removeAttr('disabled');

      event.stopImmediatePropagation();
    });

    $(document).on('change', '.ids-product-documents .section-language select#language', (event) => {
      if ($('.ids-product-documents .section-fulltext input#fulltext').val().length) {
        this._ajaxGetRecords(event.currentTarget, 'fulltext');
      } else {
        this._ajaxGetRecords(event.currentTarget, 'document');
      }

      $('.section-document-info').hide();

      event.stopImmediatePropagation();
    });
  }

  /**
   * ajax function
   *
   * @param $self
   * @param $requestType
   * @returns void
   */
  async _ajaxGetRecords($self, $requestType) {
    var $additionalParam = '';
    var $fullTextSearch = '';

    if ($requestType === 'modelserie') {
      $additionalParam =
        '&' +
        $('.ids-product-documents .section-type select#type').attr('name') +
        '=' +
        $('.ids-product-documents .section-type select#type option:selected').val();
    }

    if ($requestType === 'product') {
      $additionalParam =
        '&' +
        $('.ids-product-documents .section-type select#type').attr('name') +
        '=' +
        $('.ids-product-documents .section-type select#type option:selected').val();
      $additionalParam =
        $additionalParam +
        '&' +
        $('.ids-product-documents .section-heatsource select#heatsource').attr('name') +
        '=' +
        $('.ids-product-documents .section-heatsource select#heatsource option:selected').val();
    }

    if ($requestType === 'document') {
      $additionalParam =
        '&' +
        $('.ids-product-documents .section-type select#type').attr('name') +
        '=' +
        $('.ids-product-documents .section-type select#type option:selected').val();
      $additionalParam =
        $additionalParam +
        '&' +
        $('.ids-product-documents .section-heatsource select#heatsource').attr('name') +
        '=' +
        $('.ids-product-documents .section-heatsource select#heatsource option:selected').val();
      $additionalParam =
        $additionalParam +
        '&' +
        $('.ids-product-documents .section-product select#product').attr('name') +
        '=' +
        $('.ids-product-documents .section-product select#product option:selected').val();
      $additionalParam =
        $additionalParam +
        '&' +
        $('.ids-product-documents .section-language select#language').attr('name') +
        '=' +
        $('.ids-product-documents .section-language select#language option:selected').val();
    }

    // if($requestType === 'language') {
    //     $additionalParam = '&'+$('.ids-product-documents .section-type select#type').attr('name')+'='+$('.ids-product-documents .section-type select#type option:selected').val();
    //     $additionalParam = $additionalParam+'&'+$('.ids-product-documents .section-heatsource select#heatsource').attr('name')+'='+$('.ids-product-documents .section-heatsource select#heatsource option:selected').val();
    //     $additionalParam = $additionalParam+'&'+$('.ids-product-documents .section-product select#product').attr('name')+'='+$('.ids-product-documents .section-product select#product option:selected').val();
    //     $additionalParam = $additionalParam+'&'+$('.ids-product-documents .section-language select#language').attr('name')+'='+$('.ids-product-documents .section-language select#language option:selected').val();
    //     $requestTypeUrl = $requestType;
    //     $requestType = 'document';
    // }

    if ($requestType === 'fulltext') {
      $additionalParam =
        '&' +
        $('.ids-product-documents .section-language select#language').attr('name') +
        '=' +
        $('.ids-product-documents .section-language select#language option:selected').val();
      $fullTextSearch = '&tx_idsproduct_documents[fulltext]=' + $('.ids-product-documents .section-fulltext input#fulltext').val();
    }

    // set url
    var $language = $('form#product-documents #actualLanguage').val();
    var $url =
      '?type=927765&' +
      $($self).attr('name') +
      '=' +
      $($self).find('option:selected').val() +
      $additionalParam +
      $fullTextSearch +
      '&tx_idsproduct_documents[requestType]=' +
      $requestType +
      '&L=' +
      $language;

    let $locationPathname = window.location.pathname;
    if ($locationPathname.substr(-1) === '/') {
      $locationPathname = $locationPathname.substr(0, $locationPathname.length - 1);
    }

    let $locationHref = window.location.href;
    $locationHref = $locationHref.replace($locationPathname + '/', $locationPathname);

    $.ajax({
      url: $locationHref + $url,
      // data: 'type='+typeNumAjaxJob,
      beforeSend: function () {
        if ($requestType === 'fulltext') {
          $('.section-document').append('<div class="preloader-small"></div>');
        } else {
          $('.section-' + $requestType).append('<div class="preloader-small"></div>');
        }
      },
      success: function (html) {
        if ($requestType === 'fulltext') {
          $('.section-document-data').html(html);
        } else {
          $('.section-' + $requestType + '-data')
            .html(html)
            .parent()
            .removeClass('inactive');
        }
      },
      complete: function () {
        if ($requestType === 'fulltext') {
          $('.section-document').fadeIn();
          $('.section-document').find('.tx-ids-product').removeClass('invisible');
        } else {
          $('.section-' + $requestType).fadeIn();
          $('.section-' + $requestType)
            .find('.tx-ids-product')
            .removeClass('invisible');
        }
        $('div.preloader-small').remove();

        // init some functions
        // _initGetRecords();
      },
      error: function (XMLHttpRequest, textStatus, errorThrown) {
        console.log('Status: ' + textStatus + ', Error: ' + errorThrown);
      },
    });
  }

  /**
   * @param $requestType
   */
  async _clearSelection($requestType) {
    // hide results
    $('.ids-product-documents .section-document-data').html(' ');

    // hide others
    if ($requestType === 'heatsource') {
      $('.ids-product-documents .section-heatsource select option').prop('selected', false);
      $('.ids-product-documents .section-heatsource select').prop('disabled', 'disabled');
      $('.ids-product-documents .section-heatsource').addClass('inactive');

      $('.ids-product-documents .section-modelserie select option').prop('selected', false);
      $('.ids-product-documents .section-modelserie select').prop('disabled', 'disabled');
      $('.ids-product-documents .section-modelserie').addClass('inactive');

      $('.ids-product-documents .section-product select option').prop('selected', false);
      $('.ids-product-documents .section-product select').prop('disabled', 'disabled');
      $('.ids-product-documents .section-product').addClass('inactive');
    }

    if ($requestType === 'modelserie') {
      $('.ids-product-documents .section-modelserie select option').prop('selected', false);
      $('.ids-product-documents .section-modelserie select').prop('disabled', 'disabled');
      $('.ids-product-documents .section-modelserie').addClass('inactive');

      $('.ids-product-documents .section-product select option').prop('selected', false);
      $('.ids-product-documents .section-product select').prop('disabled', 'disabled');
      $('.ids-product-documents .section-product').addClass('inactive');
    }

    if ($requestType === 'product') {
      $('.ids-product-documents .section-product select option').prop('selected', false);
      $('.ids-product-documents .section-product select').prop('disabled', 'disabled');
      $('.ids-product-documents .section-product').addClass('inactive');
    }

    if ($requestType === 'fulltext') {
      $('.ids-product-documents .section-type select option').prop('selected', false);
      // $('.ids-product-documents .section-type select').prop('disabled', 'disabled');
      // $('.ids-product-documents .section-type').addClass('inactive');

      $('.ids-product-documents .section-heatsource select option').prop('selected', false);
      $('.ids-product-documents .section-heatsource select').prop('disabled', 'disabled');
      $('.ids-product-documents .section-heatsource').addClass('inactive');

      $('.ids-product-documents .section-modelserie select option').prop('selected', false);
      $('.ids-product-documents .section-modelserie select').prop('disabled', 'disabled');
      $('.ids-product-documents .section-modelserie').addClass('inactive');

      $('.ids-product-documents .section-product select option').prop('selected', false);
      $('.ids-product-documents .section-product select').prop('disabled', 'disabled');
      $('.ids-product-documents .section-product').addClass('inactive');
    }

    // language section
    $('.ids-product-documents .section-language select').prop('disabled', 'disabled');
    $('.ids-product-documents .section-language').addClass('inactive');
  }
}

export default IdsProduct;
