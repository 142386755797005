import $ from 'jquery';
// import 'jquery-ui';
import '@idsolutions/html-locale';
import 'popper.js';
import 'bootstrap';
// import 'jquery-colorbox';
import 'slick-carousel';
import 'jquery-match-height';
import 'hammerjs';
import 'google-charts';
import '@idsolutions/image-magnifier-glass';
import 'jquery-datetimepicker';
import './Lib/jQuerySimpleCounter';
import './Lib/Powermail/Form';
import './Lib/Powermail/Tabs';
import 'jquery-colorbox';

// Parsley
import './Lib/parsley';
import 'parsleyjs';

import * as Modules from './Modules';

// bootstrap table translations
// import 'bootstrap-table/dist/locale/bootstrap-table-de-DE';

window.jQuery = $;
window.$ = $;

/**
 * Loop module classes and call CLASS.create().init(). Note: Both methods have to exist. No check, if they
 * are not defined you'll get an error.
 *
 * @param {*} jsModules JS module classes
 */
const initModules = async (jsModules) => jsModules.forEach(async (jsModule) => jsModule.create().init());

/**
 * On document ready init all modules
 */
$(async () => initModules(Object.keys(Modules).map((key) => Modules[key])));
