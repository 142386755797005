import $ from 'jquery';
import BaseModule from './BaseModule';

/**
 * Main project
 */
class References extends BaseModule {
  async init() {
    if ($('form#searchLocation').length !== 0) {
      const specialCountries = $('form#searchLocation input#address').data('special-countries').split(',');
      const selectedCountry = $('form#searchLocation select#country option:selected').val();
      if ($.inArray(selectedCountry, specialCountries) !== -1) {
        $('form#searchLocation input#address').attr('required', 'required');
      }
      $(document).on('change', $('form#searchLocation select#country'), (e, selectedCountry) => {
        selectedCountry = $(e.currentTarget).find('option:selected').val();
        if ($.inArray(selectedCountry, specialCountries) === -1) {
          $('form#searchLocation input#address').removeAttr('required');
        } else {
          $('form#searchLocation input#address').attr('required', 'required');
        }
      });
    }
  }
}

export default References;
