import $ from 'jquery';
import BaseModule from './BaseModule';
import { Carousel, Toast } from 'bootstrap';
// import Swiper, { Navigation, Pagination } from 'swiper/core';

/**
 * Main project
 */
class Project extends BaseModule {
  async init() {
    $(document).on('show.bs.modal', '#designSurfaceModal', (e) => {
      const myCarousel = document.querySelector('#carouselDesignSurfaceModal');
      const carousel = new Carousel(myCarousel, {
      });
      carousel.to($(e.relatedTarget).data('bsSlideTo'));
    });

    // $(document).on('#configurationModalButton', 'click', (e) => {
    //   $('#configurationModal .modal-body').load('https://slm.ochsner.com/formulare/pclight/form/?lang=de&country=DE&direct=1', function() {
    //     $('#myModal').modal({ show: true });
    //   });
    // });

    // Load content in modal on activation

    // const configurationModal = document.querySelector('#configurationModal');
    // configurationModal.addEventListener('show.bs.modal', (_event) => {
    //   console.log('load ');
    //   this._loadContent('https://slm.ochsner.com/formulare/pclight/form/?lang=de&country=DE&direct=1');
    // });

    // const swiper = new Swiper('.heat-pump-types-overview-test', {
    //   slidesPerView: 4,
    //   spaceBetween: 30,
    //   centeredSlides: true,
    //   pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    //   },
    // });
    //
    // const swiperProducts = new Swiper('.product-menu', {
    //   slidesPerView: 6,
    //   loop: false,
    //   scrollbar: {
    //     el: '.swiper-scrollbar',
    //   },
    // });

    // me._initSlick('.heat-pump-types-overview', 2, false, false, 1, false, true);
    // me._initSlick('.ce-menu-thumbnail-list', 6, false, false, 2, true);
    // me._initSlick('.news .news-list-view-100', 1, true, false, 1, false);
    // me._initSlick('.tx-ids-blog .news-list-view-101-items', 1, false, true, 1, true);
    this._initSlick('.testimonials-list', 4, false, false, 1, false);
    // me._initSlick('.testimonials-slider-items', 2, false, false, 1, false);
    // me._initSlickThumbsPager('.ids-products-imageslider', 1, false, true, 1, false);

    this._initCarousel();
    this._initColorbox();
    this._initDataLayer();
    this._initFinalSettings();
    this._initHashDetection();
    this._initLanguageSelector();
    this._initMainMenu();
    this._initPowermail();
    this._copyToClicpboard();
    this._toggleSidebarMenu();
    this._initScrollCarousel();

    //
    // google maps scroll disabling
    // disable scroll zooming and bind back the click event
    // ---------------------------------------- //
    var onMapMouseleaveHandler = function (event) {
      var that = $(this);

      that.on('click', onMapClickHandler);
      that.off('mouseleave', onMapMouseleaveHandler);
      that.find('div').css('pointer-events', 'none');
    };

    var onMapClickHandler = function (event) {
      var that = $(this);

      // Disable the click handler until the user leaves the map area
      that.off('click', onMapClickHandler);

      // Enable scrolling zoom
      that.find('div').css('pointer-events', 'auto');

      // Handle the mouse leave event
      that.on('mouseleave', onMapMouseleaveHandler);
    };

    // Enable map zooming with mouse scroll when the user clicks the ma
    $('.maps.embed-container').on('click', onMapClickHandler);
  }

  // Define function to load content from remote file
  async _loadContent(url) {
    var httpRequest = new XMLHttpRequest();

    httpRequest.onreadystatechange = () => {
      // httpRequest.setRequestHeader('Access-Control-Allow-Origin', true);
      // Processing the server response
      if (httpRequest.readyState === XMLHttpRequest.DONE) {
        console.log('if');
        if (httpRequest.status === 200) {
          this._updateModal(httpRequest.responseText);
        } else {
          alert('There was a problem with the request.');
        }
      }
      console.log('after if');
    };

    httpRequest.open('GET', url, true);
    httpRequest.send();
  };

  // Define function to update modal based on response
  async _updateModal(response) {
    var myModal = document.getElementById('myModal');
    myModal.querySelector('.modal-body').innerHTML = response;
  }

  async _initScrollCarousel() {
    const scrollItemWrapperWidth = $('.carousel-wrapper').width();
    const scrollItemWidth = $('.carousel-scroll .scroll-item').width() + 32;
    const scrollItemCounter = $('.carousel-scroll .scroll-item').length;
    const scrollItemTotalWidth = scrollItemWidth * scrollItemCounter;

    if (scrollItemTotalWidth < scrollItemWrapperWidth) {
      $('.carousel-button').addClass('d-none');
    }

    $(window).on('resize', (e) => {
      const scrollItemWrapperWidth = $('.scrolling-content').width();
      const scrollItemWidth = $('.carousel-scroll .scroll-item').width() + 32;
      const scrollItemCounter = $('.carousel-scroll .scroll-item').length;
      const scrollItemTotalWidth = scrollItemWidth * scrollItemCounter;

      if (scrollItemTotalWidth < scrollItemWrapperWidth) {
        $('.carousel-button').addClass('d-none');
      } else {
        $('.carousel-button').removeClass('d-none');
      }
    });

    $(document).on('click', '.carousel-button', (e) => {
      const scrollItemWidth = $('.carousel-scroll .scroll-item').width();
      const scrollPositionCurrent = $('.carousel-scroll').scrollLeft();
      const slideNumber = scrollPositionCurrent / scrollItemWidth;
      const scrollPosition = (Math.round(slideNumber) * scrollItemWidth);

      if (e.target.className === 'right' || e.target.parentNode.className === 'right') {
        $('.carousel-scroll').animate({ scrollLeft: scrollPosition + scrollItemWidth }, 800);
      }
      if (e.target.className === 'left' || e.target.parentNode.className === 'left') {
        $('.carousel-scroll').animate({ scrollLeft: scrollPosition - scrollItemWidth }, 800);
      }
    });
  }

  async _toggleSidebarMenu() {
    $(document).on('click', '.toggle-sidebar-menu', (e) => {
      $('.sidebar-menu').toggleClass('show');
      $('body').toggleClass('no-scrolling');

      if ($(e.currentTarget).find('i.fas').hasClass('fa-chevron-right')) {
        $(e.currentTarget).find('i.fa-chevron-right').removeClass('fa-chevron-right').addClass('fa-chevron-left');
      } else if ($(e.currentTarget).find('i.fas').hasClass('fa-chevron-left')) {
        $(e.currentTarget).find('i.fa-chevron-left').removeClass('fa-chevron-left').addClass('fa-chevron-right');
      }
    });
  }

  //
  // Copy to clipboard
  // -----------------------------------------------
  async _copyToClicpboard() {
    $(document).on('click', '.copy-to-clipboard', (event) => {
      event.preventDefault();

      // const inputc = document.body.append(document.createElement('input'));
      const $temporary = $('<input>');
      $('body').append($temporary);
      $temporary.val($(event.currentTarget).data('uri')).trigger('select');
      document.execCommand('copy');
      $temporary.remove();

      const toastElementList = $(event.currentTarget).data('pos').length > 0 ? Array.prototype.slice.call(document.querySelectorAll('.toast-' + $(event.currentTarget).data('pos'))) : Array.prototype.slice.call(document.querySelectorAll('.toast-default'));

      toastElementList.map(function (toastElement) {
        return new Toast(toastElement, {
          delay: 2500
        }).show();
      });
    });
  }

  // //
  // // Copy to clipboard
  // // -----------------------------------------------
  // async _copyToClicpboard() {
  //   const me = this;
  //   $(document).on('click', '.copy-to-clipboard', (e) => {
  //     const inputc = document.body.appendChild(document.createElement('input'));
  //     inputc.value = window.location.href;
  //     inputc.focus();
  //     inputc.select();
  //     document.execCommand('copy');
  //     inputc.parentNode.removeChild(inputc);
  //
  //     const toastElList = [].slice.call(document.querySelectorAll('.toast'))
  //     const toastList = toastElList.map(function (toastEl) {
  //       return new Toast(toastEl, {
  //         delay: 2500
  //       }).show();
  //     });
  //   });
  // }

  //
  // Carousel
  // -----------------------------------------------
  async _initCarousel() {
    $('.page-media .carousel-inner .item, .page-mediaContentPages .carousel-inner .item').first().addClass('active');

    if ($('.page-media .carousel-inner .item').length === 1) {
      $('.page-media .carousel-control').hide();
    }

    if ($('.page-media .carousel-inner .item').length > 1) {
      for (let i = 0; i < $('.page-media .carousel-inner .item').length; i++) {
        if (i === 0) {
          $('.page-media .carousel-indicators').append('<li data-target="#carouselMedia" data-slide-to="' + i + '" class="active">' + i + '</li>');
        } else {
          $('.page-media .carousel-indicators').append('<li data-target="#carouselMedia" data-slide-to="' + i + '">' + i + '</li>');
        }
      }
    }
  }

  //
  // Main Menu
  // -----------------------------------------------
  async _initLanguageSelector() {
    $('nav.languagenavigation > ul > li').on('click', function() {
      $(this).children('ul').slideToggle();
    });
  }

  //
  // Main Menu
  // -----------------------------------------------
  async _initMainMenu() {
    // $(window).on('load', () => {
    //   //
    //   $('.navbar-toggle').removeClass('d-none');
    // });

    $(document).on('click', '.mainnavigation-item', (e) => {
      e.preventDefault();
      if ($(e.currentTarget).hasClass('active')) {
        $(e.currentTarget).removeClass('active');
        $(e.currentTarget).parent().find('.mainnavigation-sub').removeClass('show');
        if ($(window).width() < 1450) {
          $(e.currentTarget).find('i').removeClass('fa-angle-down');
          $(e.currentTarget).find('i').addClass('fa-angle-right');
        }
      } else {
        $('.mainnavigation-top a').removeClass('active');
        $('.mainnavigation-sub').removeClass('show');
        $(e.currentTarget).addClass('active');
        $(e.currentTarget).parent().find('.mainnavigation-sub').addClass('show');
        if ($(window).width() < 1450) {
          $(e.currentTarget).find('i').removeClass('fa-angle-right');
          $(e.currentTarget).find('i').addClass('fa-angle-down');
        }
      }
    });

    $(document).on('mouseenter', '.mainnavigation-sub-item', (e) => {
      $('.mainnavigation-sub-item').removeClass('active');
      $('.mainnavigation-sub-child-wrapper').removeClass('fade-in');
      $('.mainnavigation-sub-wrapper').removeClass('active');

      $(e.currentTarget).addClass('active');
      $(e.currentTarget).parents('.mainnavigation-sub-wrapper').addClass('active');
      $(e.currentTarget).next('.mainnavigation-sub-child-wrapper').addClass('fade-in');
    });

    $(document).on('mouseleave', '.mainnavigation-sub', (e) => {
      $('.mainnavigation-sub-item').removeClass('active');
      $('.mainnavigation-sub-child-wrapper').removeClass('fade-in');
      $('.mainnavigation-sub-wrapper').removeClass('active');
    });

    // if ($(window).width() > 1023) {
    //   $('nav.navbar-collapse > ul > li').each(function() {
    //     var $dropdownItem = $(this).find('ul.dropdown-menu li');
    //     var $numberOfSubitems = $($dropdownItem).length;
    //     var $itemWidth = 100 / $numberOfSubitems;
    //     $($dropdownItem).css('width', $itemWidth + '%');
    //   });
    // }
    //
    // // mobile settings
    // if ($(window).width() < 1024) {
    //   $('.navbar-nav ul.dropdown-menu').addClass('show');
    // } else {
    //   $('.navbar-nav ul.dropdown-menu').removeClass('show');
    // }
    // $(window).on('resize', (e) => {
    //   if ($(window).width() < 1024) {
    //     $('.navbar-nav ul.dropdown-menu').addClass('show');
    //   } else {
    //     $('.navbar-nav ul.dropdown-menu').removeClass('show');
    //   }
    // });
    // // $('.navbar-main-backlink span.backlink').on('click', function(e) {
    // //   $(this).parents('.dropdown').find('a.dropdown-toggle').trigger('click');
    // // });
    //
    $('button.navbar-toggle').on('click', function(e) {
      $('body').toggleClass('no-scrolling');
      $('.mainnavigation-overlay').toggleClass('d-none');
      $('.mainnavigation-top').fadeToggle();
    });
  }

  //
  // Colorbox
  // ------------------------------------------------------------------------- //
  async _initColorbox() {
    $('a.colorbox').colorbox({
      rel: 'gal',
      current: '{current} / {total}',
      maxWidth: '90%',
      maxHeight: '90%',
      previous: '<i class="fa fa-chevron-left fa-2x" aria-hidden="true"></i>',
      next: '<i class="fa fa-chevron-right fa-2x" aria-hidden="true"></i>',
      close: '<i class="fa fa-times fa-2x" aria-hidden="true"></i>',
      onLoad: function() {
      },
      onComplete: function() {
      }
    });
  }

  //
  // Slick Slider
  // -----------------------------------------------
  async _initSlick(containerClass, numberOfSlides, showArrows, fade, numberOfSlidesMobile, autoplay, centerMode = false) {
    let numberOfSlides1024 = 0;
    if (numberOfSlides > 1) {
      numberOfSlides1024 = Math.round(numberOfSlides / 2);
    } else {
      numberOfSlides1024 = numberOfSlides;
    }

    $(containerClass).not('.slick-initialized').slick({
      dots: true,
      fade: fade,
      infinite: true,
      arrows: showArrows,
      autoplay: autoplay,
      autoplaySpeed: 4000,
      prevArrow: '<span class="slick-prev"><i class="fas fa-chevron-left text-body"></i></span>',
      nextArrow: '<span class="slick-next"><i class="fas fa-chevron-right text-body"></i></span>',
      // prevArrow: '<span class="glyphicon glyphicon-menu-left slick-prev" aria-hidden="true"></span>',
      // nextArrow: '<span class="glyphicon glyphicon-menu-right slick-next" aria-hidden="true"></span>',
      speed: 300,
      slidesToShow: numberOfSlides,
      slidesToScroll: numberOfSlides,
      centerMode: centerMode,

      responsive: [
        // {
        //   breakpoint: 1024,
        //   settings: {
        //     slidesToShow: numberOfSlides1024,
        //     slidesToScroll: numberOfSlides1024,
        //   }
        // },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: numberOfSlidesMobile,
            slidesToScroll: numberOfSlidesMobile
          }
        }
      ]
    });

    // if (showSlide) {
    //   $(containerClass).slickGoTo(parseInt(showSlide));
    // }
  }

  async _initSlickThumbsPager(containerClass, numberOfSlides, showArrows, fade, numberOfSlidesMobile, autoplay) {
    $(containerClass).not('.slick-initialized').slick({
      lazyLoad: 'ondemmand',
      dots: true,
      fade: fade,
      infinite: true,
      arrows: showArrows,
      autoplay: autoplay,
      autoplaySpeed: 4000,
      prevArrow: '<span class="glyphicon glyphicon-menu-left slick-prev" aria-hidden="true"></span>',
      nextArrow: '<span class="glyphicon glyphicon-menu-right slick-next" aria-hidden="true"></span>',
      speed: 300,
      slidesToShow: numberOfSlides,
      slidesToScroll: numberOfSlides,
      customPaging: function(slider, i) {
        var thumb = $(slider.$slides[i]).find('figure.image img').data('thumb');
        return '<a><img src="' + thumb + '" class="img-fluid"></a>';
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: Math.round(numberOfSlides / 2),
            slidesToScroll: Math.round(numberOfSlides / 2),
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: numberOfSlidesMobile,
            slidesToScroll: numberOfSlidesMobile
          }
        }
      ]
    });
  }

  //
  // final initial settings
  // -----------------------------------------------
  async _initFinalSettings() {
    // news teaser height
    var $elementHeightMH = $('.pagets__special_start .page-content .article.gridelement .ce-bodytext-wrapper .article .ce-img-behind').outerHeight();
    $('.pagets__special_start .news-list-view-101 .news-list-item').height($elementHeightMH);

    $(window).resize(function() {
      $elementHeightMH = $('.pagets__special_start .page-content .article.gridelement .ce-bodytext-wrapper .article .ce-img-behind').outerHeight();
      $('.pagets__special_start .news-list-view-101 .news-list-item').height($elementHeightMH);
    });

    // ul nav-tabs
    $.each($('ul.nav-tabs'), function() {
      $(this).find('li a').first().addClass('active');
    });
    $.each($('div.tab-content'), function() {
      $(this).find('.tab-pane').first().addClass('active show');
    });

    $(document).on('click', 'ul.nav-tabs li a', (e) => {
      $('ul.nav-tabs li.active').removeClass('active');
      $(e.currentTarget).parent('li').addClass('active');
    });
  }

  //
  // powermail functions
  // -----------------------------------------------
  async _initPowermail() {
    $('form.powermail_form_18 #powermail_field_betreff_1').on('change', (event) => {
      window.location.href = $(event.currentTarget).next('label').find('a').attr('href');
    });

    // scroll to form top after ajax submit
    $('.powermail_submit').on('click', function() {
      setTimeout(function() {
        $('body, html').animate({ scrollTop: ($('div.tx-powermail').offset().top) - 140 }, 'slow', 'easeInCubic');
      }, 5000);
    });
  }

  //
  // data layer push functions
  // -----------------------------------------------
  async _initDataLayer() {
    // // references
    // $('form#searchLocation').submit(function (e) {
    //   var $country = $(this).find('select#country option:selected').val();
    //   var $zip = $(this).find('input#address').val();
    //   var $type = $(this).find('select#category option:selected').val();
    //
    //   var dataLayer = [{ key: 'GTM-WT8BGWW', company: 'Ochsner' }];
    //   dataLayer.push({
    //     'systempartner-land': $country,
    //     'systempartner-plz': $zip,
    //     'systempartner-typ': $type
    //   });
    // });
  }

  //
  // is a hash in url
  // -----------------------------------------------
  async _initHashDetection() {
    // $(window).load('', function () {
    if (window.location.hash) {
      var hash = window.location.hash;
      $('html, body').animate({
        scrollTop: $('div' + hash).offset().top - 140
      }, 500);
    }
    // });
  }
}

export default Project;
