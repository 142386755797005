import $ from 'jquery';
import BaseModule from './BaseModule';

/**
 * Main project
 */
class Savingsticker extends BaseModule {
  async init() {
    const me = this;

    me._initCounter();
  }

  async _initCounter() {
    const me = this;
    me._getCounterValue();
    setInterval(me._getCounterValue, 1000);
  }

  async _getCounterValue() {
    var $value = 0;
    var $startValue = 0;
    var $increaseValue = parseFloat($('.counter .counter-value').attr('data-increasevalue'));

    $startValue = parseFloat($('.counter .counter-value').attr('data-value'));
    $value = $startValue + $increaseValue;

    //  $('.numbers').html(function (i, html) {
    //    return html.replace(/(\d)/g, '<span>$1</span>');
    //  });
    var $valueInt = parseInt($value);
    var $valueFixed = $value.toFixed(3);
    var $characters = $.trim($valueFixed).split('');
    //  var $characters = $.trim($valueInt).split("");
    var $valueWrapped = '';
    var $characterLength = $characters.length - 1;
    var $charactersEachR = $valueFixed.replace('.', '');
    var $charactersEach = $charactersEachR.split('');
    $.each($charactersEach, function(i, el) {
      $valueWrapped = $valueWrapped + '<span class="counter-number">' + el + '</span>';

      var $numberstring = ($characterLength / 3).toFixed(2);
      var $substr = $numberstring.split('.');

      if ($substr[1] === '33' && i === 0) {
        $valueWrapped = $valueWrapped + '<span class="counter-number counter-number-comma">.</span>';
      }
      if ($substr[1] === '66' && i === 0) {
        $valueWrapped = $valueWrapped + '<span class="counter-number counter-number-comma">.</span>';
      }

      if (i === ($characterLength - 4)) {
        $valueWrapped = $valueWrapped + '<span class="counter-number counter-number-comma">,</span>';
      } else if ((i === 3 || i === 6 || i === 9 || i === 12) && i !== ($characterLength - 1)) {
        $valueWrapped = $valueWrapped + '<span class="counter-number counter-number-comma">.</span>';
      }
    });

    $('.counter .counter-value').html($valueWrapped);
    $('.counter .counter-value').attr('data-value', parseFloat($value));
  }

  async _initCountUp() {
    if (typeof $.fn.jQuerySimpleCounter !== 'undefined') {
      $('.counter .counter-value .counter-number').each(function() {
        if (!$(this).hasClass('animated')) {
          $(this).addClass('animated');
          $(this).jQuerySimpleCounter({
            start: 0,
            end: $(this).attr('data-value'),
            duration: 1500
          });
        }
      });
    }
  }
}

export default Savingsticker;
