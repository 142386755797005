import $ from 'jquery';
import BaseModule from './BaseModule';
import Cookies from 'js-cookie';

/**
 * Main project
 */
class Job extends BaseModule {
  async init() {
    this.filterArray = {};
    this.showFilter = {};

    this._filterJobsByCookie();

    const $categoryHash = window.location.hash;
    if ($categoryHash) {
      $(`select#category option[data-hashtag="${$categoryHash}"]`).prop('selected', 'selected');
      this.filterArray.category = $(`select#category option[data-hashtag="${$categoryHash}"]`).val();
      Cookies.set('jobfilter', JSON.stringify(this.filterArray), -1);
      this._filterJobs();
      // this.filterArray['category']
    }

    $('.job-list-wrapper').removeClass('d-none');

    $(document).on('change', 'select.job-select', (event) => {
      const $filterButton = $(event.currentTarget).attr('id');
      const $selectedValue = $(event.currentTarget).find('option:selected').val();

      if ($selectedValue === '0') {
        $('.ids-jobs select option').removeAttr('disabled');
        $('.ids-jobs select').val('0');

        $('.ids-jobs .job-list').removeClass('d-none');

        this.filterArray = {};
        Cookies.set('jobfilter', null, -1);
        return false;
      }

      this.filterArray[$filterButton] = $selectedValue;
      Cookies.set('jobfilter', JSON.stringify(this.filterArray), -1);
      this._filterJobs();
    });

    // save selected options to cookie
  }

  async _filterJobs() {
    // handle job list
    $('.ids-jobs .job-list').addClass('d-none');

    let i = 1;
    $.each(this.filterArray, (key, value) => {
      if (i === 1) {
        $(`.ids-jobs .job-list[data-${key}="${value}"]`).removeClass('d-none');
      } else {
        $.each($('.ids-jobs .job-list:not(.d-none)'), (listKey, listValue) => {
          if ($(listValue).data(key) !== parseInt(value)) {
            $(listValue).addClass('d-none');
          }
        });
      }

      i++;
    });

    // handle filter options
    this._handleFilter();
  }

  async _handleFilter() {
    const $filterCategory = {};
    const $filterCountry = {};
    const $filterCity = {};
    const $filterEmploymentType = {};

    $.each($('.ids-jobs .job-list:not(.d-none)'), (listKey, listValue) => {
      $filterCategory[$(listValue).data('category')] = $(listValue).data('category');
      $filterCountry[$(listValue).data('country')] = $(listValue).data('country');
      $filterCity[$(listValue).data('city')] = $(listValue).data('city');
      $filterEmploymentType[$(listValue).data('employmenttype')] = $(listValue).data('employmenttype');
    });

    $('.ids-jobs select#category option:not(:eq(0))').attr('disabled', 'disabled');
    $.each($filterCategory, (key, value) => {
      $(`.ids-jobs select#category option[value="${value}"]`).removeAttr('disabled');
    });

    $('.ids-jobs select#city option:not(:eq(0))').attr('disabled', 'disabled');
    $.each($filterCity, (key, value) => {
      $(`.ids-jobs select#city option[value="${value}"]`).removeAttr('disabled');
    });

    $('.ids-jobs select#country option:not(:eq(0))').attr('disabled', 'disabled');
    $.each($filterCountry, (key, value) => {
      $(`.ids-jobs select#country option[value="${value}"]`).removeAttr('disabled');
    });

    $('.ids-jobs select#employmentType option:not(:eq(0))').attr('disabled', 'disabled');
    $.each($filterEmploymentType, (key, value) => {
      $(`.ids-jobs select#employmentType option[value="${value}"]`).removeAttr('disabled');
    });

    $.each(this.filterArray, (key, value) => {
      $(`.ids-jobs select#${key} option[value="${value}"]`).prop('selected', 'selected');
    });
  }

  async _filterJobsByCookie() {
    if ($('.tx-ids-jobs').length === 0) {
      Cookies.set('jobfilter', '');
    }

    if (Cookies.get('jobfilter') !== '') {
      this.filterArray = JSON.parse(Cookies.get('jobfilter'));
      this._filterJobs();
    }
  }
}

export default Job;
