import * as Parsley from 'parsleyjs';
import ParsleyDe from '../../Lang/parsley.de';
import ParsleyFr from '../../Lang/parsley.fr';
import ParsleyPl from '../../Lang/parsley.pl';

class ParsleyConfig {
  static async addLanguage(langShort, language, setLocale = true) {
    Parsley
      .addMessages(langShort, language)
      .setLocale(setLocale ? langShort : '');

    return ParsleyConfig;
  }

  static async addValidator(validatorName, validator) {
    Parsley.addValidator(validatorName, validator);

    return ParsleyConfig;
  }
}

// Add languages to parsley
const [html] = document.getElementsByTagName('html')
const lang = html.getAttribute('lang');
if (lang === 'de') {
  ParsleyConfig.addLanguage('de', ParsleyDe);
}
if (lang === 'fr') {
  ParsleyConfig.addLanguage('fr', ParsleyFr);
}
if (lang === 'pl') {
  ParsleyConfig.addLanguage('pl', ParsleyPl);
}
