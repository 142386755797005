/**
 *  _     _           _       _   _
 * (_)   | |         | |     | | (_)
 *  _  __| |___  ___ | |_   _| |_ _  ___  _ __  ___
 * | |/ _` / __|/ _ \| | | | | __| |/ _ \| '_ \/ __|
 * | | (_| \__ \ (_) | | |_| | |_| | (_) | | | \__ \
 * |_|\__,_|___/\___/|_|\__,_|\__|_|\___/|_| |_|___/.
 *
 * @package   Themes
 *
 * @copyright 2021 idsolutions gmbh
 *
 * @see       https://code.idsolutions.at/
 */

import BaseModule from './BaseModule';
import $ from 'jquery';

/**
 * Carousel module. All modules must have an async function called
 * {@link Carousel.init init()}. This function will be called on document ready.
 */
class CarouselBootstrap extends BaseModule {
  /**
   * This function will be called on document ready.
   */
  async init() {
    $('.carousel').each(function() {
      const items = $('.carousel-item', this);
      // reset the height
      items.css('min-height', 0);
      // set the height
      const maxHeight = Math.max.apply(null,
        items.map(function() {
          return $(this).outerHeight()
        }).get());
      items.css('min-height', maxHeight + 'px');
    })

    $(window).on('resize', (e) => {
      $('.carousel').each(function() {
        const items = $('.carousel-item', this);
        // reset the height
        items.css('min-height', 0);
        // set the height
        const maxHeight = Math.max.apply(null,
          items.map(function() {
            return $(this).outerHeight()
          }).get());
        items.css('min-height', maxHeight + 'px');
      })
    });
  }
}

export default CarouselBootstrap;
