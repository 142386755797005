import $ from 'jquery';
import BaseModule from './BaseModule';
import Cookies from 'js-cookie';
import { Modal } from 'bootstrap';
/**
 * Main project
 */
class IdsIpToCountry extends BaseModule {
  async init() {
    const me = this;

    me.countryLangCheck();
  }

  /**
   * Main ajax call
   *
   * @param string container
   * @param string url
   * @returns void
   */
  async countryLangCheck() {
    const me = this;

    let locationHref = window.location.href;
    let pathname = window.location.pathname;

    if (locationHref.indexOf('type') > 0 || locationHref.indexOf('#') > 0) {
      return;
    }

    if (pathname.substr(-1) === '/') {
      pathname = pathname.substr(0, pathname.length - 1);
    }

    locationHref = locationHref.replace(pathname + '/', pathname);

    // if (locationHref.substr(-1) === '/') {
    //     locationHref = locationHref.substr(0, locationHref.length - 1);
    // }

    let uri = locationHref + '?type=938267';

    if (locationHref.indexOf('?') > 0) {
      uri = locationHref + '&type=938267';
    }

    // if (uri.indexOf('gclid') === -1 && uri.indexOf('fbclid') === -1) {
    $.ajax({
      url: uri,
      beforeSend: function() {},
      success: function(html) {
        if ($('div.iptocountry-modal').length === 0) {
          $('body').append(html);
        }
      },
      complete: function() {
        me.countryLangFunc();
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        console.log('Status: ' + textStatus + ', Error: ' + errorThrown);
      }
    });
    // }
  }

  async countryLangFunc() {
    $('.location-info').on('click', (e) => {
      $('.location-items').slideToggle();

      e.stopImmediatePropagation();
      e.stopPropagation();
    });

    const cookieIptocountry = Cookies.get('iptocountry');
    if (!cookieIptocountry && document.location.pathname.length > 1 && !/bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i.test(navigator.userAgent)) {
      // $('.iptocountry-modal').modal('show');
      // let ipToCountryModal = new bootstrap.Modal(document.getElementsByClassName('iptocountry-modal'));
      // ipToCountryModal.show();
      const selectCountryLangModalElement = document.getElementById('selectCountryLangModal');
      if (selectCountryLangModalElement) {
        const selectCountryLangModal = new Modal(selectCountryLangModalElement, {
          keyboard: false
        })

        if ($(document).find('#CybotCookiebotDialog')) {
          const timer = setInterval((e) => {
            // console.log('X: ', $(document).find('.CybotMultilevel.CybotCookiebotDialogActive').length);
            if ($(document).find('.CybotMultilevel.CybotCookiebotDialogActive').length === 0) {
              selectCountryLangModal.show();
              setTimeout(() => { clearInterval(timer) }, 100);
            }
          }, 3000);
        }
      }

      // $('.iptocountry-modal').show().addClass('show');
      // $('.modal-backdrop').addClass('show');
    } else if (cookieIptocountry && document.location.pathname.length === 1 && !/bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i.test(navigator.userAgent)) {
      location.href = cookieIptocountry;
    }

    // $(document).on('click', 'button.close-modal', (e) => {
    //   $('.iptocountry-modal').hide();
    // });

    // $('.iptocountry-modal .modal-footer a.btn-success, .iptocountry-modal .modal-footer button.btn-default, .iptocountry-container .iptocountry-select-lang').on('click', function() {
    $(document).on('click', '.iptocountry-modal .modal-footer a.btn-success, .iptocountry-modal .modal-footer button.btn-secondary, .iptocountry-container .iptocountry-select-lang', function(selectCountryLangModal) {
      Cookies.set('iptocountry', $(this).data('lang'), { expires: 30 });

      // $(selectCountryLangModal).on('hidden.bs.modal', (e) => {
      //
      // });
    });

    // $('.location-info').on('click', (e) => {
    //     $('.location-items').slideToggle();
    //
    //     e.stopImmediatePropagation();
    //     e.stopPropagation();
    // });
    //
    // let cookieIptocountry = Cookies.get('iptocountry');
    // let cookieIptocountryS = Cookies.get('iptocountry_s');
    // console.log('XX', document.location.pathname.length);
    // if((!cookieIptocountry && cookieIptocountryS && document.location.pathname.length > 1 && !/bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i.test(navigator.userAgent))) {
    //     $('.iptocountry-modal').modal('show');
    // // } else if((!cookieIptocountry && !cookieIptocountryS && document.location.pathname.length > 1 && !/bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i.test(navigator.userAgent))) {
    // //     $('.iptocountry-modal').modal('show');
    // } else if(cookieIptocountry && document.location.pathname.length === 1 && !/bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i.test(navigator.userAgent)) {
    //     location.href=cookieIptocountry;
    // }
    //
    //
    // // $('.iptocountry-modal .modal-footer a.btn-success, .iptocountry-modal .modal-footer button.btn-default, .iptocountry-container .iptocountry-select-lang').on('click', function() {
    // $(document).on('click', '.iptocountry-modal .modal-footer a.btn-success, .iptocountry-modal .modal-footer button.btn-default, .iptocountry-container .iptocountry-select-lang', function() {
    //     Cookies.set('iptocountry', $(this).data('lang'), { expires: 30 });
    //     Cookies.set('iptocountry_s', $(this).data('lang'), { expires: 30 });
    //     // alert('click');
    // });
  }

  // async strpos (haystack, needle, offset) {
  //   var i = (haystack+'').indexOf(needle, (offset || 0));
  //   return i === -1 ? false : i;
  // }
}

export default IdsIpToCountry;
