import $, { readyException } from 'jquery';
import BaseModule from './BaseModule';

/**
 * Main project
 */
class Conveyinginfo extends BaseModule {
  municipalSelected = false;

  async init() {
    const me = this;

    if ($('input#conveyinginfo-country').val()) {
      // disable tabs if a country was submitted
      if ($('form#conveyinginfoform-AT input#conveyinginfo-step').val() !== 'basicinfo') {
        $('.nav-tabs li:nth-child(1)').addClass('active');
        $('.nav-tabs li:nth-child(2)').removeClass('active');
      }
      if ($('form#conveyinginfoform-DE input#conveyinginfo-step').val() !== 'basicinfo') {
        $('.nav-tabs li:nth-child(2)').addClass('active');
        $('.nav-tabs li:nth-child(1)').removeClass('active');
        // $('.nav-tabs li:nth-child(2) a').tab('show');
        $('.nav-tabs li:nth-child(2) a').show();
        $('.tab-content .tab-pane:nth-child(1)').removeClass('active show');
        $('.tab-content .tab-pane:nth-child(2)').addClass('active show');
      }
      if ($('form#conveyinginfoform-CH input#conveyinginfo-step').val() !== 'basicinfo') {
        $('.nav-tabs li:nth-child(3)').addClass('active');
        $('.nav-tabs li:nth-child(1)').removeClass('active');
        $('.nav-tabs li:nth-child(2)').removeClass('active');
        // $('.nav-tabs li:nth-child(3) a').tab('show');
        $('.nav-tabs li:nth-child(3) a').show();
        $('.tab-content .tab-pane:nth-child(1)').removeClass('active show');
        $('.tab-content .tab-pane:nth-child(2)').removeClass('active show');
        $('.tab-content .tab-pane:nth-child(3)').addClass('active show');
      }
    }

    // zip code entered
    me._zipCodeFunction('AT');
    me._zipCodeFunction('DE');
    me._zipCodeFunction('CH');

    // municipal changed
    // municipalFunction(true);

    // show fieldsets
    me._form('AT');
    me._form('DE');
    me._form('CH');
  }

  async _form(specialId) {
    const me = this;

    $('form#conveyinginfoform-' + specialId).on('submit', function(event) {
      let requiredFields = true;

      // remove error-fillup class
      $('form#conveyinginfoform-' + specialId + ' input.required, form#conveyinginfoform-' + specialId + ' select.required option:selected').each(function(event) {
        $(this).removeClass('error-fillup');
      });

      let filled = true;
      $('form#conveyinginfoform-' + specialId + ' input.required, form#conveyinginfoform-' + specialId + ' select.required option:selected').each(function(event) {
        if (!$(this).val() || $(this).val() === '0' || ($(this).attr('type') === 'checkbox' && $(this).prop('checked') === false)) {
          const el = $(this);
          if (el[0].nodeName.toLowerCase() === 'option') {
            $(this).parent().addClass('error-fillup');
          } else {
            $(this).addClass('error-fillup');
          }

          if (filled === true) {
            filled = false;
          }
        }
      });

      if (filled === false) {
        requiredFields = false
      }

      // if (me.checkRequiredFields(specialId) !== false) {
      //   requiredFields = true;
      // }

      $('form#conveyinginfoform-' + specialId + ' .required-group-usage-checkbox').each(function() {
        var requiredFieldsGroupUsage = false;
        $('input[type="checkbox"]', this).each(function() {
          if ($(this).prop('checked') === true) {
            requiredFieldsGroupUsage = true;
          }
        });

        if (requiredFieldsGroupUsage === false) {
          $('input[type="checkbox"]', this).first().prop('checked', true);
        }
      });

      if (requiredFields === true) {
        return true;
      }

      $('html, body').animate({
        scrollTop: $('form#conveyinginfoform-' + specialId).offset().top
      }, 600);

      return false;
    });
  }

  /**
   * Main ajax call
   *
   * @param string container
   * @param string url
   * @returns void
   */
  async ajaxCall(container, uri) {
    const me = this;
    const endOfUri = uri.indexOf('?');
    let locationHref = uri.substr(0, endOfUri);
    const requestUri = uri.substr(endOfUri, uri.length);

    if (locationHref.substr(-1) === '/') {
      locationHref = locationHref.substr(0, locationHref.length - 1);
    }

    uri = locationHref + requestUri;

    // console.log('uri', uri);

    $.ajax({
      url: uri,
      data: 'tx_conveyinginfo_conveyinginfo[action]=ajax&type=826547',
      beforeSend: function() {
        $(container).append('<div class="preloader"></div>');
      },
      success: function(html) {
        $(container).html(html);
      },
      complete: function() {
        me.municipalFunction(true);
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        console.log('Status: ' + textStatus + ', Error: ' + errorThrown);
      }
    });
  }

  async _zipCodeFunction(specialId) {
    const me = this;

    // zip code was entered
    $('form#conveyinginfoform-' + specialId + ' #conveyinginfo-zip').on('keyup', function() {
      if ($(this).val().length > 3) {
        me.municipalSelected = false;
        var uri = $(location).attr('href') + '?tx_conveyinginfo_conveyinginfo[sessionId]=' + $('form#conveyinginfoform-' + specialId + ' #conveyinginfo-sessionId').val() + '&tx_conveyinginfo_conveyinginfo[zip]=' + $(this).val() + '&tx_conveyinginfo_conveyinginfo[fdwType]=gid';
        me.ajaxCall('form#conveyinginfoform-' + specialId + ' #conveyinginfo-cont-municipal', uri);
      }
    });

    if ($('form#conveyinginfoform-' + specialId + ' #conveyinginfo-zip').length) {
      if ($('form#conveyinginfoform-' + specialId + ' #conveyinginfo-zip').val().length > 3) {
        me.municipalSelected = false;
        var uri = $(location).attr('href') + '?tx_conveyinginfo_conveyinginfo[sessionId]=' + $('form#conveyinginfoform-' + specialId + ' #conveyinginfo-sessionId').val() + '&tx_conveyinginfo_conveyinginfo[zip]=' + $('form#conveyinginfoform-' + specialId + ' #conveyinginfo-zip').val() + '&tx_conveyinginfo_conveyinginfo[fdwType]=gid';
        me.ajaxCall('form#conveyinginfoform-' + specialId + ' #conveyinginfo-cont-municipal', uri);
      };
    }
  }

  async municipalFunction(municipalSelectedTrueOrFalse) {
    const me = this;

    if (!me.municipalSelected) {
      me.municipalSelected = municipalSelectedTrueOrFalse;
      var municipal = $('#conveyinginfo-municipal option:selected').val();
      var uri = $(location).attr('href') + '?tx_conveyinginfo_conveyinginfo[sessionId]=' + $('#conveyinginfo-sessionId').val() + '&tx_conveyinginfo_conveyinginfo[zip]=' + $('#conveyinginfo-zip').val() + '&tx_conveyinginfo_conveyinginfo[municipal]=' + municipal + '&tx_conveyinginfo_conveyinginfo[fdwType]=evuid';
      me.ajaxCall('#conveyinginfo-cont-energysupplier', uri);
    } else {
      // municipal has changed
      $('#conveyinginfo-municipal').on('change', function() {
        me.municipalSelected = municipalSelectedTrueOrFalse;
        var municipal = $(this).find('option:selected').val();
        var uri = $(location).attr('href') + '?tx_conveyinginfo_conveyinginfo[sessionId]=' + $('#conveyinginfo-sessionId').val() + '&tx_conveyinginfo_conveyinginfo[zip]=' + $('#conveyinginfo-zip').val() + '&tx_conveyinginfo_conveyinginfo[municipal]=' + municipal + '&tx_conveyinginfo_conveyinginfo[fdwType]=evuid';
        me.ajaxCall('#conveyinginfo-cont-energysupplier', uri);
      });
    }
  }

  async checkRequiredFields(specialId) {
    // remove error-fillup class
    $('form#conveyinginfoform-' + specialId + ' input.required, form#conveyinginfoform-' + specialId + ' select.required option:selected').each(function(event) {
      $(this).removeClass('error-fillup');
    });

    var filled = true;
    $('form#conveyinginfoform-' + specialId + ' input.required, form#conveyinginfoform-' + specialId + ' select.required option:selected').each(function(event) {
      if (!$(this).val() || $(this).val() === '0' || ($(this).attr('type') === 'checkbox' && $(this).prop('checked') === false)) {
        var el = $(this);
        if (el[0].nodeName.toLowerCase() === 'option') {
          $(this).parent().addClass('error-fillup');
        } else {
          $(this).addClass('error-fillup');
        }

        if (filled === true) {
          filled = false;
        }
      }
    });

    if (filled === false) {
      return false;
    }
    return true;
  }
}

export default Conveyinginfo;
