/**
 * Modules stuff
 */
// export { default as Charts } from './Chart';
// export { default as RangeSlider } from './RangeSlider';

/**
 * Project stuff
 */
// export { default as Parsley } from './ParsleyOwn';
// export { default as Shrink } from './Shrink';
// export { default as ModalDialog } from './ModalDialog';
// export { default as DataHref } from './DataHref';

/// /////////////////////////////////////////////////////
export { default as Conveyinginfo } from './Conveyinginfo';
export { default as IdsIpToCountry } from './IdsIpToCountry';
// export { default as IdsSlider } from './IdsSlider';
export { default as Savingsticker } from './Savingsticker';
export { default as References } from './References';
export { default as CarouselBootstrap } from './CarouselBootstrap';
export { default as Project } from './Project';
export { default as IdsGallery } from './IdsGallery';
export { default as Job } from './Job';
export { default as IdsProduct } from './IdsProduct';
